<template>
  <footer class="event-footer">
    <div class="event-footer-container">
      <!-- Logo and Text Section -->
      <div class="event-footer-brand">
        <img
          src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/logo/elixir.png"
          alt="Align STEM Club"
          class="event-footer-logo event-logo-white"
        />
        <p class="event-footer-text">
          Robothon envisions a future where STEM education, Robotics, and AI are
          accessible to all, fostering innovation and problem-solving among
          young minds in Pakistan. As a free training-based competition, we
          strive to bridge the resource gap by providing hands-on learning
          opportunities to underprivileged institutions. Through this platform,
          students tackle real-world challenges and develop skills that prepare
          them for a tech-driven future. This year, we align with UN Sustainable
          Development Goal 14: Life Below Water, inspiring solutions for marine
          sustainability and environmental conservation.
        </p>
      </div>

      <!-- Contact Links Section -->
      <div class="event-footer-links">
        <h3 class="event-footer-title">Contact Information</h3>
        <nav class="event-footer-nav">
          <a href="tel:03352721234" class="event-footer-link">
            <i class="fas fa-phone"></i>
            <span>0335-2721234</span>
          </a>
          <a href="mailto:alignstemclub@gmail.com" class="event-footer-link">
            <i class="fas fa-envelope"></i>
            <span>alignstemclub@gmail.com</span>
          </a>
          <a
            href="https://maps.google.com/?q=ST 1, Block G, North Nazimabad, Karachi"
            target="_blank"
            class="event-footer-link"
          >
            <i class="fas fa-location-dot"></i>
            <span>ST 1, Block G, North Nazimabad, Karachi</span>
          </a>
        </nav>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterRobothon",
};
</script>
