<template>
  <div>
    <div v-if="dataLoading" class="custom-loader-wrapper">
      <div class="custom-loader"></div>
    </div>
    <template v-else-if="pageData">
      <div
        v-if="pageData.Banner"
        class="full-width style-105126"
        :style="{
          'background-image': `linear-gradient(to top, transparent, rgba(0, 0, 0, 0.5)), url(${smallMedia(
            pageData.Banner.Image.data.attributes
          )})`,
        }"
      ></div>

      <div
        v-if="pageData.AboutInfo"
        class="style-105032 region layout-region indi-section"
      >
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177570"
              >
                <div
                  class="layout_block_177570 region page-region"
                  id="page_region_174764"
                >
                  <div class="page-row row">
                    <div class="last-col first-col page-col span24 col">
                      <div
                        class="page-block style-105241 clearfix"
                        data-cid="1"
                        id="content_3121857"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <!-- <img
                                src="@/assets/images/aboutHeading.png"
                                alt=""
                              /> -->
                              <span class="heading-black">
                                {{ pageData.AboutInfo.HeadingBlack }}
                              </span>
                              <span class="heading-red">
                                {{ pageData.AboutInfo.HeadingRed }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="page-row row">
                    <div class="first-col page-col col">
                      <div
                        class="page-block clearfix"
                        data-cid="2"
                        id="content_3122164"
                      >
                        <div class="content">
                          <div class="content-wrap">
                            <div
                              class="my-content"
                              v-html="
                                convertSpecialChars(pageData.AboutInfo.Details)
                              "
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Leadership Messages Section -->
      <div v-if="pageData.messages" class="leadership-messages-section">
        <div class="container">
          <div class="messages-header">
            <span class="heading-black">{{
              pageData.messages.headingBlack
            }}</span>
            <span class="heading-red">{{ pageData.messages.headingRed }}</span>
          </div>

          <div class="leadership-grid">
            <div
              v-for="(message, index) in pageData.messages.newMessages"
              :key="message.id"
              class="leadership-profile-card"
              data-aos="fade-up"
              data-aos-duration="900"
              :data-aos-delay="index * 100"
              :class="{ active: selectedProfileIndex === index }"
              @click="showProfileDetails(index)"
            >
              <div class="profile-image">
                <img
                  :src="smallMedia(message.picture.data.attributes)"
                  :alt="message.author"
                />
              </div>
              <div class="profile-info">
                <h3 class="profile-title">{{ message.designation }}</h3>
                <h2 class="profile-name">{{ message.author }}</h2>
                <button class="view-profile-btn">View Profile</button>
              </div>
            </div>
          </div>

          <!-- Profile Details Modal -->
          <div
            v-if="selectedProfile"
            class="profile-modal"
            @click.self="closeModal"
          >
            <div
              class="profile-modal-content"
              data-aos="fade-up"
              data-aos-duration="400"
            >
              <button class="close-modal-btn" @click="closeModal">
                &times;
              </button>
              <div class="modal-profile-content">
                <div class="modal-profile-header">
                  <div class="modal-profile-image">
                    <img
                      :src="smallMedia(selectedProfile.picture.data.attributes)"
                      :alt="selectedProfile.author"
                    />
                  </div>

                  <div>
                    <div class="modal-profile-footer">
                      <h2 class="modal-profile-name">
                        {{ selectedProfile.author }}
                      </h2>
                      <p class="modal-profile-title">
                        {{ selectedProfile.designation }}
                      </p>
                      <p class="modal-profile-qualification">
                        {{ selectedProfile.qualification }}
                      </p>

                      <hr class="modal-profile-hr" />
                    </div>
                    <div
                      class="modal-profile-message my-content"
                      v-html="convertSpecialChars(selectedProfile.message)"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="pageData.Vision.length > 0"
        class="style-105032 region layout-region indi-section single-section"
      >
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177570"
              >
                <div
                  v-for="item in pageData.Vision"
                  :key="item.id"
                  class="layout_block_177570 region page-region set-bottom-margin"
                  id="page_region_174764"
                >
                  <div class="page-row row" style="margin: 0">
                    <div class="last-col first-col page-col span24 col">
                      <div
                        class="page-block style-105241 clearfix"
                        data-cid="1"
                        id="content_3121857"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <!-- <img src="@/assets/images/vision.png" alt="" /> -->
                              <span class="heading-black">
                                {{ item.HeadingBlack }}
                              </span>
                              <span class="heading-red">
                                {{ item.HeadingRed }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="page-row row">
                    <div
                      class="first-col page-col span12 col single-image-wrapper"
                      data-aos="fade-up"
                      data-aos-duration="1200"
                      :class="{ 'image-right': item.Side == 'Right' }"
                    >
                      <div
                        class="page-block style-103675 clearfix"
                        data-cid="1"
                        id="content_3120486"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <img
                                class="center-content-image"
                                :src="smallMedia(item.Image.data.attributes)"
                                alt=""
                                loading="lazy"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="first-col page-col span12 col center-content-wrapper"
                      data-aos="flip-right"
                      data-aos-duration="1200"
                    >
                      <div
                        class="page-block clearfix"
                        data-cid="2"
                        id="content_3122164"
                      >
                        <div class="content">
                          <div class="center-content">
                            <div
                              class="my-content"
                              v-html="convertSpecialChars(item.Details)"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="pageData.ElixirStaff"
        class="style-103644 region layout-region"
        id="layout_region_3"
      >
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177570"
              >
                <div
                  class="layout_block_177570 region page-region"
                  id="page_region_174764"
                >
                  <div class="style-103676 page-row row adjust-order">
                    <div
                      class="first-col page-col span16 col other-video"
                      data-aos="fade-up"
                      data-aos-duration="1200"
                    >
                      <div
                        class="page-block style-103675 clearfix"
                        data-cid="1"
                        id="content_3120486"
                      >
                        <div class="content text">
                          <div class="content-wrap bgvid-dimensions">
                            <video
                              playsinline=""
                              controls
                              controlslist="nodownload"
                              id="bgvid"
                              style="width: 100%"
                            >
                              <source
                                type="video/mp4"
                                :src="
                                  smallMedia(
                                    pageData.ElixirStaff.Video.data.attributes
                                  )
                                "
                              />
                            </video>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="last-col page-col span8 col center-content-wrapper"
                      data-aos="flip-right"
                      data-aos-duration="1200"
                    >
                      <div
                        class="page-block style-103675 clearfix"
                        data-cid="1"
                        id="content_3121855"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <!-- <img
                                src="@/assets/images/staffHeading.png"
                                alt=""
                              /> -->
                              <span class="heading-black side-heading">
                                {{ pageData.ElixirStaff.HeadingBlack }}
                              </span>
                              <span class="heading-red side-heading">
                                {{ pageData.ElixirStaff.HeadingRed }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="">
                        <div
                          class="my-content"
                          v-html="
                            convertSpecialChars(pageData.ElixirStaff.Details)
                          "
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="pageData.ElixirValue"
        class="style-105032 region layout-region indi-section"
      >
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177570"
              >
                <div
                  class="layout_block_177570 region page-region"
                  id="page_region_174764"
                >
                  <div class="page-row row">
                    <div class="last-col first-col page-col span24 col">
                      <div
                        class="page-block style-105241 clearfix"
                        data-cid="1"
                        id="content_3121857"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <!-- <img src="@/assets/images/value.png" alt="" /> -->
                              <span class="heading-black">
                                {{ pageData.ElixirValue.HeadingBlack }}
                              </span>
                              <span class="heading-red">
                                {{ pageData.ElixirValue.HeadingRed }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="page-row row"
                    data-aos="fade-up"
                    data-aos-duration="900"
                  >
                    <div
                      class="my-content"
                      v-html="convertSpecialChars(pageData.ElixirValue.Details)"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="pageData.QEC"
        class="style-105032 region layout-region indi-section"
      >
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177570"
              >
                <div
                  class="layout_block_177570 region page-region"
                  id="page_region_174764"
                >
                  <div class="page-row row">
                    <div class="last-col first-col page-col span24 col">
                      <div
                        class="page-block style-105241 clearfix"
                        data-cid="1"
                        id="content_3121857"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <!-- <img src="@/assets/images/qec.png" alt="" /> -->
                              <span class="heading-black">
                                {{ pageData.QEC.HeadingBlack }}
                              </span>
                              <span class="heading-red">
                                {{ pageData.QEC.HeadingRed }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="page-row row"
                    data-aos="fade-up"
                    data-aos-duration="900"
                  >
                    <div
                      class="my-content"
                      v-html="convertSpecialChars(pageData.QEC.Details)"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="pageData.Form" class="style-104895">
        <div class="container">
          <div class="form-container">
            <div class="form-wrapper">
              <form @submit.prevent="formSubmit">
                <h2 class="form-heading">{{ pageData.Form.Heading }}</h2>

                <div class="form-group-wrapper">
                  <div class="form-group">
                    <label class="form-label" for="name">Name</label>
                    <input
                      class="form-control"
                      type="text"
                      name="name"
                      id="name"
                      v-model.trim="mailObj.name"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label class="form-label" for="email">Email</label>
                    <input
                      class="form-control"
                      type="email"
                      name="email"
                      id="email"
                      v-model="mailObj.email"
                      required
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label class="form-label" for="phone">Phone</label>
                  <input
                    class="form-control"
                    type="number"
                    name="phone"
                    id="phone"
                    v-model="mailObj.phone"
                    required
                  />
                </div>
                <div class="form-group">
                  <label class="form-label" for="msg">Message</label>
                  <textarea
                    class="form-control"
                    name="msg"
                    id="msg"
                    rows="4"
                    v-model.trim="mailObj.message"
                    required
                  />
                </div>
                <div class="form-button-wrapper">
                  <button
                    type="submit"
                    class="button-primary variant-secondary"
                    :disabled="request"
                  >
                    <div
                      v-if="request"
                      class="custom-mini-loader"
                      style="margin: auto"
                    ></div>
                    <span v-else>{{ pageData.Form.ButtonText }}</span>
                  </button>
                </div>
              </form>
            </div>
            <div class="form-info">
              <h2 class="form-info-heading">
                {{ pageData.Form.CardHeading }}
              </h2>
              <hr />
              <div
                v-for="item in pageData.Form.Points"
                :key="item.id"
                class="form-info-item"
              >
                <!-- arrow-white -->
                <img src="@/assets/images/arrow-red.png" alt="" />
                {{ item.Text }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div v-else class="custom-loader-wrapper">
      <h1>Page Not Found 🕵🏻‍♀️</h1>
      <p>
        Oops! 😖 The page you're looking for does not exist or have been
        temporarily unavailable.
      </p>
    </div>
  </div>
</template>
<script>
import { convertSpecialChars, smallMedia } from "@/utils/utils";
import axios from "axios";

export default {
  name: "AboutNew",
  data() {
    return {
      mailObj: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
      request: false,
      // receiver: "aseefjawaid123@gmail.com",
      receiver: "officesupport@theelixirschools.com",
      pageData: null,
      dataLoading: false,
      selectedProfile: null,
      selectedProfileIndex: null,
    };
  },
  created() {
    this.LoadData();
  },
  methods: {
    convertSpecialChars,
    smallMedia,
    formSubmit() {
      // console.log(this.mailObj);
      this.request = true;

      const config = {
        method: "post",
        url: "https://mail.appicksolutions.com/cloudious",
        data: {
          emailTo: this.receiver,
          subject: "The Elixir School | Contact",
          text: `Name: ${this.mailObj.name}\nEmail: ${this.mailObj.email}\nPhone: ${this.mailObj.phone}\nMessage: ${this.mailObj.message}`,
          html: "",
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res);
          if (res.data.status == "success") {
            this.$toast.success("Message sent successfully!");
            this.mailObj = {
              name: "",
              email: "",
              phone: "",
              message: "",
            };
          } else {
            this.$toast.error("Something went wrong!");
          }
        })
        .catch((err) => {
          // console.log(err);
          this.$toast.error("Something went wrong!");
        })
        .finally(() => (this.request = false));
    },
    LoadData() {
      this.dataLoading = true;
      let config = {
        method: "get",
        url: `${this.$store.state.domain}/about-uses?populate=deep`,
        headers: {
          Authorization: this.$store.state.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          if (res.data.data.length > 0) {
            this.pageData = res.data.data[0].attributes;
          } else this.pageData = null;
        })
        .catch((err) => console.log(err))
        .finally(() => (this.dataLoading = false));
    },
    showProfileDetails(index) {
      this.selectedProfileIndex = index;
      this.selectedProfile = this.pageData.messages.newMessages[index];
      document.body.style.overflow = "hidden";
    },
    closeModal() {
      this.selectedProfileIndex = null;
      this.selectedProfile = null;
      document.body.style.overflow = "auto";
    },
    formatQualifications(qualificationText) {
      if (!qualificationText) return [];
      return qualificationText
        .split(/\s*-\s*|\s*\n\s*/)
        .filter((q) => q.trim().length > 0);
    },
  },
};
</script>
<style scoped>
.style-105241,
.form-heading,
.form-info-heading {
  font-family: "Poppins", serif !important;
}

.my-content,
.form-container {
  font-family: "Noto Sans", serif !important;
}

.leadership-messages-section {
  padding: 80px 0;
  background: linear-gradient(135deg, #f8f9fa, #ffffff);
  position: relative;
  overflow: hidden;
}

.leadership-messages-section::before {
  content: "";
  position: absolute;
  width: 600px;
  height: 600px;
  background: linear-gradient(
    135deg,
    rgba(255, 77, 77, 0.05),
    rgba(255, 128, 128, 0.02)
  );
  border-radius: 50%;
  top: -300px;
  right: -200px;
  z-index: 0;
}

.leadership-messages-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.leadership-message-card {
  background: #ffffff;
  border-radius: 0;
  padding: 0;
  margin-bottom: 60px;
  box-shadow: none;
  position: relative;
  z-index: 1;
  overflow: hidden;
  border: none;
  transition: all 0.3s ease;
}

.leadership-message-card:hover {
  transform: translateY(-5px);
}

.leadership-message-content {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.leadership-image {
  flex: 0 0 140px;
  max-width: 140px;
  overflow: hidden;
  position: relative;
  margin-right: 0;
  height: 100%;
  transition: all 0.3s ease;
}

.leadership-image img {
  width: 100%;
  height: auto;
  object-fit: contain;
  display: block;
  transition: all 0.3s ease;
}

.leadership-message-card:hover .leadership-image {
  transform: scale(1.02);
}

.leadership-details {
  flex: 1;
  padding: 0;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.leadership-message {
  font-size: 1.25rem;
  line-height: 1.25;
  color: #444;
  white-space: pre-line;
  font-family: "Noto Sans", sans-serif;
  text-align: justify;
  margin-bottom: 20px;
}

.leadership-author-info {
  margin-top: auto;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 15px;
}

.leadership-name {
  font-size: 1.2rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 0;
  font-family: "Poppins", sans-serif;
}

.leadership-designation {
  font-size: 0.9rem;
  font-weight: 600;
  color: #333;
  margin-top: 6px;
  margin-bottom: 6px;
  font-family: "Poppins", sans-serif;
}

.leadership-qualification {
  font-size: 0.75rem;
  color: #666;
  margin-bottom: 0;
  font-family: "Poppins", sans-serif;
  line-height: 1.3;
}

.messages-header {
  text-align: center;
  margin-bottom: 50px;
}

.messages-header .heading-black,
.messages-header .heading-red {
  font-size: 2.5rem;
  font-weight: bold;
  display: inline-block;
}

.messages-header .heading-red {
  color: #ff4d4d;
  margin-left: 10px;
}

@media (max-width: 768px) {
  .messages-header .heading-black,
  .messages-header .heading-red {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .messages-header .heading-black,
  .messages-header .heading-red {
    font-size: 1.8rem;
    display: block;
  }

  .messages-header .heading-red {
    margin-left: 0;
    margin-top: 5px;
  }
}

@media (max-width: 768px) {
  .leadership-message-content {
    flex-direction: column;
    gap: 15px;
  }

  .leadership-image {
    flex: 0 0 100%;
    max-width: 140px;
    margin: 0 auto 20px;
  }

  .leadership-details {
    text-align: center;
  }

  .leadership-message {
    text-align: left;
    font-size: 16px;
  }

  .leadership-author-info {
    text-align: start;
  }
}

/* Leadership Grid Styles */
.leadership-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin: 0 auto;
  max-width: 1200px;
  justify-content: center;
}

.leadership-profile-card {
  background: #f8f9fa;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  height: 100%;
  width: calc(33.333% - 20px);
  min-width: 250px;
  max-width: 350px;
}

.leadership-profile-card:hover,
.leadership-profile-card.active {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.leadership-profile-card.active {
  border: 2px solid #ff4d4d;
}

.profile-image {
  width: 100%;
  height: auto;
  overflow: hidden;
  background: #eee;
}

.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.5s ease;
}

.leadership-profile-card:hover .profile-image img {
  transform: scale(1.05);
}

.profile-info {
  padding: 15px;
  text-align: center;
  background: white;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.profile-title {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 5px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.profile-name {
  font-size: 1rem;
  color: #333;
  margin-bottom: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.view-profile-btn {
  background: var(--primary);
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  transition: all 0.3s ease;
  margin-top: auto;
  font-size: 0.9rem;
}

.view-profile-btn:hover {
  filter: brightness(1.1);
}

/* Modal Styles */
.profile-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
}

.profile-modal-content {
  background: white;
  border-radius: 10px;
  width: 90%;
  max-width: 900px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  padding: 30px;
}

.close-modal-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
  z-index: 10;
}

.modal-profile-content {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.modal-profile-header {
  display: flex;
  gap: 30px;
  align-items: flex-start;
}

.modal-profile-image {
  flex: 0 0 150px;
  max-width: 150px;
  height: 180px;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.modal-profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modal-profile-message {
  flex: 1;
  font-size: 1.2rem;
  line-height: 1.2;
  color: #444;
  font-family: "Noto Sans", sans-serif;
  text-align: justify;
}

.modal-profile-footer {
  /* border-top: 1px solid rgba(0, 0, 0, 0.1); */
  padding-top: 10px;
}

.modal-profile-name {
  font-size: 1.2rem;
  line-height: 1.2rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 5px;
  font-family: "Poppins", sans-serif;
}

.modal-profile-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--primary);
  margin-bottom: 10px;
  font-family: "Poppins", sans-serif;
}

.modal-profile-hr {
  border: 1px solid var(--primary);
  margin: 10px 0;
}

.modal-profile-qualification {
  font-size: 0.9rem;
  color: gray;
  font-weight: 600;
  font-family: "Noto Sans", sans-serif;
  line-height: 1.2;
}

@media (max-width: 768px) {
  .leadership-profile-card {
    width: calc(50% - 10px);
    min-width: 220px;
  }
  .leadership-grid {
    gap: 20px;
  }

  .profile-image img {
    /* height: 200px; */
    object-fit: contain;
  }

  .profile-name {
    font-size: 1.1rem;
  }

  .modal-profile-header {
    flex-direction: column;
  }

  /* .modal-profile-image {
    margin: 0 auto 20px;
  } */

  .modal-profile-name {
    font-size: 1rem;
  }

  .modal-profile-title {
    font-size: 1rem;
  }

  .modal-profile-qualification,
  .modal-profile-message {
    font-size: 0.8rem;
  }
  /* .view-profile-btn {
    font-size: 0.9rem;
    width: 40%;
    margin: 0 auto;
  } */

  /* .profile-modal-content {
    width: auto;
  } */
}

@media (max-width: 480px) {
  .leadership-grid {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }

  .profile-image {
    height: 180px;
  }

  .profile-modal-content {
    padding: 20px;
  }
  .leadership-profile-card {
    width: 100%;
    max-width: 100%;
  }
}
</style>
