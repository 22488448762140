<template>
  <div class="event-form-wrapper">
    <div class="event-header-container">
      <div class="event-header-logo-wrapper">
        <img
          src="@/assets/images/elixir-logo.png"
          alt="Elixir Logo"
          class="event-header-logo"
        />
        <img
          src="@/assets/images/robothon-logo.png"
          alt="Robothon Logo"
          class="event-header-logo"
        />
        <img
          src="@/assets/images/stem-logo.png"
          alt="Align Stem Logo"
          class="event-header-logo"
        />
      </div>
    </div>

    <div class="event-registration-container">
      <!-- Form Section -->
      <div class="event-form-container">
        <h1 class="event-form-title">Robothon 2.0 Registration</h1>
        <div class="event-form-subwrapper">
          <p class="event-form-fees-subheading required-field">
            Last date to register is 20 March 2025.
          </p>

          <form @submit.prevent="handleSubmit" class="event-form">
            <!-- <h3 class="event-form-section-title">
              Team Details (If Applicable)
            </h3> -->

            <div class="event-form-group">
              <label for="team_name" class="event-form-label"
                >Team Name <span class="required-field">*</span></label
              >
              <input
                type="text"
                id="team_name"
                v-model="formData.team_name"
                class="event-form-input"
                required
              />
            </div>

            <div class="event-form-group">
              <label class="event-form-label">Number of Team Members:</label>
              <div class="event-form-radio-group">
                <label
                  v-for="n in [2, 4]"
                  :key="n"
                  class="event-form-radio-label"
                  :class="{ 'humanoid-note': n === 4 }"
                >
                  <input
                    type="radio"
                    v-model="formData.team_members"
                    :value="n"
                    class="event-form-radio"
                  />
                  {{ n }}
                  <span v-if="n === 4" class="event-form-note"
                    >(Only for Humanoid Robot Challenge)</span
                  >
                </label>
              </div>
            </div>

            <div
              v-for="(member, index) in formData.team_members"
              :key="index"
              class="member-section"
            >
              <h3 class="member-title">Member - {{ index + 1 }}</h3>

              <div class="member-details">
                <div class="member-form-row">
                  <div class="event-form-group">
                    <label :for="'name_' + index" class="event-form-label">
                      Name <span class="required-field">*</span>
                    </label>
                    <input
                      :id="'name_' + index"
                      v-model="formData.members[index].name"
                      type="text"
                      class="event-form-input"
                      required
                    />
                  </div>

                  <div class="event-form-group">
                    <label
                      :for="'father_name_' + index"
                      class="event-form-label"
                    >
                      Father's Name <span class="required-field">*</span>
                    </label>
                    <input
                      :id="'father_name_' + index"
                      v-model="formData.members[index].father_name"
                      type="text"
                      class="event-form-input"
                      required
                    />
                  </div>
                </div>

                <div class="member-form-row">
                  <div class="event-form-group">
                    <label :for="'dob_' + index" class="event-form-label">
                      Date of Birth <span class="required-field">*</span>
                    </label>
                    <input
                      :id="'dob_' + index"
                      v-model="formData.members[index].date_of_birth"
                      type="date"
                      class="event-form-input"
                      required
                    />
                  </div>

                  <div class="event-form-group">
                    <label :for="'class_' + index" class="event-form-label">
                      Class <span class="required-field">*</span>
                    </label>
                    <input
                      :id="'class_' + index"
                      v-model="formData.members[index].class"
                      type="text"
                      class="event-form-input"
                      required
                    />
                  </div>
                </div>

                <div class="member-form-row">
                  <div class="event-form-group">
                    <label :for="'contact_' + index" class="event-form-label">
                      Contact <span class="required-field">*</span>
                    </label>
                    <input
                      :id="'contact_' + index"
                      v-model="formData.members[index].contact"
                      type="tel"
                      class="event-form-input"
                      required
                    />
                  </div>

                  <div class="event-form-group">
                    <label :for="'tshirt_' + index" class="event-form-label">
                      T-Shirt Size <span class="required-field">*</span>
                    </label>
                    <select
                      :id="'tshirt_' + index"
                      v-model="formData.members[index].tshirt_size"
                      class="event-form-select"
                      required
                    >
                      <option value="">Select Size</option>
                      <option value="XS">XS</option>
                      <option value="S">S</option>
                      <option value="M">M</option>
                      <option value="L">L</option>
                      <option value="XL">XL</option>
                      <option value="XXL">XXL</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="event-form-group">
              <label for="school" class="event-form-label"
                >School <span class="required-field">*</span></label
              >
              <input
                type="text"
                id="school"
                v-model="formData.school"
                class="event-form-input"
                required
              />
            </div>

            <div class="event-form-group">
              <label for="school_representative_name" class="event-form-label"
                >School Representative Name
                <span class="required-field">*</span></label
              >
              <input
                type="text"
                id="school_representative_name"
                v-model="formData.school_representative_name"
                class="event-form-input"
                required
              />
            </div>

            <div class="event-form-group">
              <label
                for="school_representative_contact"
                class="event-form-label"
                >School Representative Contact
                <span class="required-field">*</span></label
              >
              <input
                type="tel"
                id="school_representative_contact"
                v-model="formData.school_representative_contact"
                class="event-form-input"
                required
              />
            </div>

            <div class="event-form-group">
              <label class="event-form-label"
                >Does your institution have a flag?
                <span class="required-field">*</span></label
              >
              <div class="event-form-radio-group">
                <label class="event-form-radio-label">
                  <input
                    type="radio"
                    v-model="formData.school_flag"
                    :value="true"
                    class="event-form-radio"
                    required
                  />
                  Yes
                </label>
                <label class="event-form-radio-label">
                  <input
                    type="radio"
                    v-model="formData.school_flag"
                    :value="false"
                    class="event-form-radio"
                    required
                  />
                  No
                </label>
              </div>
            </div>

            <div class="event-form-group">
              <label for="category" class="event-form-label"
                >Category <span class="required-field">*</span></label
              >
              <select
                id="category"
                v-model="formData.category"
                class="event-form-select"
                required
              >
                <option value="">Select Category</option>
                <option
                  v-for="(categoryData, categoryName) in categories"
                  :key="categoryName"
                  :value="categoryName"
                >
                  {{ categoryName }} (Max {{ categoryData.maxTeamSize }} per
                  team)
                </option>
              </select>
            </div>

            <div class="event-form-group">
              <label for="subcategory" class="event-form-label"
                >Subcategory <span class="required-field">*</span></label
              >
              <select
                id="subcategory"
                v-model="formData.subcategory"
                class="event-form-select"
                required
                :disabled="!formData.category"
              >
                <option value="">Select Subcategory</option>
                <option
                  v-for="subcategory in availableSubcategories"
                  :key="subcategory"
                  :value="subcategory"
                >
                  {{ subcategory }}
                </option>
              </select>
            </div>

            <div class="event-form-fees-payment">
              <div class="payment-info">
                <i class="fas fa-info-circle"></i>
                <h5>Payment Information</h5>
              </div>
              <ul class="payment-details">
                <li><strong>Bank Name:</strong> DUBAI ISLAMIC BANK LIMITED</li>
                <li><strong>Account Title:</strong> THE ELIXIR SCHOOL</li>
                <li><strong>Account No:</strong> 0254898002</li>
                <li><strong>IBAN A/C No:</strong> PK02DUIB0000000254898002</li>
              </ul>
            </div>

            <div class="event-form-group">
              <label class="event-form-label">
                Payment Proof <span class="required-field">*</span>
              </label>

              <div class="payment-proof-upload">
                <input
                  type="file"
                  id="payment_proof"
                  @change="handleFileUpload"
                  accept="image/*"
                  class="payment-proof-input"
                />
                <label
                  for="payment_proof"
                  class="payment-proof-label"
                  :class="{ 'has-file': formData.payment_proof }"
                >
                  <template v-if="isUploading">
                    <div class="button-spinner preview-spinner"></div>
                    <span>Uploading...</span>
                  </template>
                  <template v-else-if="!formData.payment_proof">
                    <i class="fas fa-plus-circle"></i>
                    <span>Upload Payment Proof</span>
                  </template>
                  <template v-else>
                    <i class="fas fa-check-circle"></i>
                    <span>Change Image</span>
                  </template>
                </label>
                <div class="payment-proof-note">
                  <strong
                    >Attach Screenshot or Receipt as a proof of payment</strong
                  >
                </div>
              </div>

              <!-- Preview Section -->
              <div v-if="formData.payment_proof" class="payment-proof-preview">
                <img
                  :src="previewUrl"
                  alt="Payment Proof"
                  class="preview-image"
                />
                <button
                  type="button"
                  @click="removeFile"
                  class="remove-file"
                  title="Remove Image"
                >
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </div>

            <div class="event-form-group">
              <div class="event-form-consent">
                <h3 class="event-form-consent-title">Consent & Declaration</h3>
                <label class="event-form-checkbox-label">
                  <input
                    type="checkbox"
                    v-model="formData.consent"
                    class="event-form-checkbox"
                    required
                  />
                  <span class="event-form-consent-text">
                    I, the undersigned, confirm that all details provided are
                    accurate. I agree to abide by the competition rules and
                    guidelines. Align STEM Club will not be responsible for any
                    injuries or damages during the event.
                  </span>
                </label>
              </div>
            </div>

            <div class="event-form-group">
              <button
                type="submit"
                class="button-primary event-form-button"
                :disabled="isSubmitting"
              >
                <template v-if="isSubmitting">
                  <div class="button-spinner"></div>
                  <span>Submitting...</span>
                </template>
                <span v-else>Submit Registration</span>
              </button>
            </div>
          </form>
        </div>
      </div>

      <!-- Fees Section -->
      <div class="event-fees-container">
        <h3 class="event-form-title">Registration Fees</h3>

        <div class="event-fees-content">
          <p class="event-form-fees-subheading">
            Check the applicable fee based on your category selection
          </p>
          <div class="event-form-fees-category">
            <div class="category-header">
              <i class="fas fa-robot"></i>
              <h4>
                LEGO Robotics
                <div class="team-size">(Max 2 per team)</div>
              </h4>
            </div>
            <div class="fee-details">
              <!-- <div class="fee-row">
                <span class="fee-type">Early Bird:</span>
                <span class="fee-amount">PKR 4,500</span>
              </div> -->
              <div class="fee-row">
                <span class="fee-type">Registration Fee:</span>
                <span class="fee-amount">PKR 6,000</span>
              </div>
            </div>
          </div>

          <div class="event-form-fees-category">
            <div class="category-header">
              <i class="fas fa-microchip"></i>
              <h4>
                Arduino Robotics
                <div class="team-size">(Max 2 per team)</div>
              </h4>
            </div>
            <div class="fee-details">
              <!-- <div class="fee-row">
                <span class="fee-type">Early Bird:</span>
                <span class="fee-amount">PKR 3,500</span>
              </div> -->
              <div class="fee-row">
                <span class="fee-type">Registration Fee:</span>
                <span class="fee-amount">PKR 5,000</span>
              </div>
            </div>
          </div>

          <div class="event-form-fees-category">
            <div class="category-header">
              <i class="fas fa-cogs"></i>
              <h4>
                Open Robotics Category
                <div class="team-size">
                  (Max 2 per team, except Humanoid - 4 members)
                </div>
              </h4>
            </div>
            <div class="fee-details">
              <div class="subcategory">
                <h5>Battle of Bots / Robo Sumo</h5>
                <!-- <div class="fee-row">
                  <span class="fee-type">Early Bird:</span>
                  <span class="fee-amount">PKR 3,500</span>
                </div> -->
                <div class="fee-row">
                  <span class="fee-type">Registration Fee:</span>
                  <span class="fee-amount">PKR 5,000</span>
                </div>
              </div>
              <div class="subcategory">
                <h5>Humanoid Robot Challenge</h5>
                <!-- <div class="fee-row">
                  <span class="fee-type">Early Bird:</span>
                  <span class="fee-amount">PKR 10,000</span>
                </div> -->
                <div class="fee-row">
                  <span class="fee-type">Registration Fee:</span>
                  <span class="fee-amount">PKR 15,000</span>
                </div>
              </div>
              <div class="subcategory">
                <h5>Hydraulic Powered Robotic Arm</h5>
                <div class="fee-row">
                  <span class="fee-type">Fixed Fee:</span>
                  <span class="fee-amount">PKR 3,000</span>
                </div>
              </div>
            </div>
          </div>

          <div class="event-form-fees-category">
            <div class="category-header">
              <i class="fas fa-plane"></i>
              <h4>
                Drone Competition
                <div class="team-size">
                  (Max 2 per team, Only Tello Edu drones allowed)
                </div>
              </h4>
            </div>
            <div class="fee-details">
              <!-- <div class="fee-row">
                <span class="fee-type">Early Bird:</span>
                <span class="fee-amount">PKR 4,500</span>
              </div> -->
              <div class="fee-row">
                <span class="fee-type">Registration Fee:</span>
                <span class="fee-amount">PKR 6,000</span>
              </div>
            </div>
          </div>

          <div class="event-form-fees-category">
            <div class="category-header">
              <i class="fas fa-lightbulb"></i>
              <h4>
                Surprise Category
                <div class="team-size">(Max 2 per team)</div>
              </h4>
            </div>
            <div class="fee-details">
              <div class="fee-row">
                <span class="fee-type">Registration Fee:</span>
                <span class="fee-amount">PKR 3,500</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "RobothonRegistration",
  data() {
    return {
      categories: {
        "LEGO-Based Robotics": {
          maxTeamSize: 2,
          subcategories: [
            "Robo Builder (Ages 5-8) - WeDo 2.0 Kit",
            "Balloon Blaster Robo Fight (Ages 7-9) - Edison V2.0",
            "Robo Mission & Maze (Ages 9-12) - LEGO EV3 & Spike Prime",
          ],
        },
        "Arduino Robotics": {
          maxTeamSize: 2,
          subcategories: [
            "Track the Line (Ages 9-16)",
            "Robo Kickoff (Soccer) (Ages 9-16)",
          ],
        },
        "Open Robotics Category": {
          maxTeamSize: 2,
          subcategories: [
            "Humanoid Robot Challenge (All resources provided)",
            "Hydraulic Powered Robotic Arm",
            "Battle of Bots (Robo War)",
            "Ring Master (Robo Sumo)",
          ],
        },
        "Drone Competition": {
          maxTeamSize: 2,
          subcategories: [
            "Sky War - The Drone Showdown (Only Tello Edu drones allowed.)",
          ],
        },
        "Surprise Category": {
          maxTeamSize: 2,
          subcategories: ["Cyber Robotics (Age 8 - 14)"],
        },
      },
      formData: {
        school: "",
        school_representative_name: "",
        school_representative_contact: "",
        school_flag: null,
        category: "",
        subcategory: "",
        team_name: "",
        team_members: 2,
        members: [
          {
            name: "",
            father_name: "",
            date_of_birth: "",
            class: "",
            tshirt_size: "",
            contact: "",
          },
          {
            name: "",
            father_name: "",
            date_of_birth: "",
            class: "",
            tshirt_size: "",
            contact: "",
          },
          {
            name: "",
            father_name: "",
            date_of_birth: "",
            class: "",
            tshirt_size: "",
            contact: "",
          },
          {
            name: "",
            father_name: "",
            date_of_birth: "",
            class: "",
            tshirt_size: "",
            contact: "",
          },
        ],
        consent: false,
        payment_proof: null,
      },
      previewUrl: null,
      isUploading: false,
      isSubmitting: false,
    };
  },
  computed: {
    availableSubcategories() {
      return this.formData.category
        ? this.categories[this.formData.category].subcategories
        : [];
    },
    maxTeamMembers() {
      if (!this.formData.category) return 2;
      if (
        this.formData.category === "Open Robotics Category" &&
        this.formData.subcategory ===
          "Humanoid Robot Challenge (All resources provided)"
      ) {
        return 4;
      }
      return this.categories[this.formData.category].maxTeamSize;
    },
  },
  watch: {
    "formData.category"() {
      // Reset subcategory when category changes
      this.formData.subcategory = "";
      // Reset team members to 2 when category changes
      this.formData.team_members = 2;
    },
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      // console.log(file);
      if (file) {
        // this.formData.payment_proof = file;
        // this.previewUrl = URL.createObjectURL(file);
        this.isUploading = true;
        let formData = new FormData();
        formData.append("file", file);
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            this.previewUrl = e.data.myresp[0].path;
            this.formData.payment_proof = this.previewUrl;
          })
          .catch((err) => {
            console.log(err);
            this.previewUrl = null;
            this.formData.payment_proof = null;
          })
          .finally(() => {
            this.isUploading = false;
          });
      }
    },
    removeFile() {
      this.formData.payment_proof = null;
      this.previewUrl = null;
      // Reset the file input
      const fileInput = document.getElementById("payment_proof");
      if (fileInput) fileInput.value = "";
    },
    async handleSubmit() {
      if (!this.formData.payment_proof) {
        this.$toast.error("Please upload payment proof.");
        return;
      }
      try {
        this.isSubmitting = true;
        // Map members array to individual fields
        const memberFields = {};
        this.formData.members.forEach((member, index) => {
          const num = index + 1;
          memberFields[`member_${num}_name`] = member.name || null;
          memberFields[`member_${num}_father_name`] =
            member.father_name || null;
          memberFields[`member_${num}_date_of_birth`] =
            member.date_of_birth || null;
          memberFields[`member_${num}_class`] = member.class || null;
          memberFields[`member_${num}_contact`] = member.contact || null;
          memberFields[`member_${num}_tshirt_size`] =
            member.tshirt_size || null;
        });

        // Prepare data for API
        const registrationData = {
          data: {
            school: this.formData.school,
            school_representative_name:
              this.formData.school_representative_name,
            school_representative_contact:
              this.formData.school_representative_contact,
            school_flag: this.formData.school_flag,
            category: this.formData.category,
            subcategory: this.formData.subcategory,
            team_name: this.formData.team_name,
            team_members: this.formData.team_members,
            consent: this.formData.consent,
            payment_proof: this.formData.payment_proof,
            registration_date: new Date().toISOString().split("T")[0],
            ...memberFields,
          },
        };
        // console.log(registrationData);

        // Make API call using axios
        const response = await axios.post(
          `${this.$store.state.domain}/elixir-robothons`,
          registrationData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.$store.state.token,
            },
          }
        );

        if (response.status === 200) {
          this.$toast.success("Registration submitted successfully!");
          this.resetForm();
          // Scroll to top smoothly
          window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
          this.$toast.error("Failed to submit registration");
        }
      } catch (error) {
        console.error("Registration error:", error);
        this.$toast.error("Failed to submit registration");
      } finally {
        this.isSubmitting = false;
      }
    },

    resetForm() {
      // Reset form data to initial state
      this.formData = {
        school: "",
        school_representative_name: "",
        school_representative_contact: "",
        school_flag: null,
        category: "",
        subcategory: "",
        team_name: "",
        team_members: 2,
        members: [
          {
            name: "",
            father_name: "",
            date_of_birth: "",
            class: "",
            tshirt_size: "",
            contact: "",
          },
          {
            name: "",
            father_name: "",
            date_of_birth: "",
            class: "",
            tshirt_size: "",
            contact: "",
          },
          {
            name: "",
            father_name: "",
            date_of_birth: "",
            class: "",
            tshirt_size: "",
            contact: "",
          },
          {
            name: "",
            father_name: "",
            date_of_birth: "",
            class: "",
            tshirt_size: "",
            contact: "",
          },
        ],
        consent: false,
        payment_proof: null,
      };
      this.previewUrl = null;

      // Reset file input
      const fileInput = document.getElementById("payment_proof");
      if (fileInput) fileInput.value = "";
    },
  },
};
</script>
