<template>
  <div>
    <HeaderNew
      v-if="!['coming-soon', 'robothon-registration'].includes($route.name)"
    />
    <router-view />
    <FooterRobothon
      v-if="['coming-soon', 'robothon-registration'].includes($route.name)"
    />
    <FooterNew v-else />
  </div>
</template>

<script>
// import Header from "@/components/Header.vue";
// import Footer from "@/components/Footer.vue";
import HeaderNew from "@/components/HeaderNew.vue";
import FooterNew from "@/components/FooterNew.vue";
import FooterRobothon from "@/components/FooterRobothon.vue";

export default {
  components: {
    // Header,
    // Footer,
    HeaderNew,
    FooterNew,
    FooterRobothon,
  },
};
</script>
<style>
.lv-dialog {
  margin: 0px 20px;
}
.lv-dialog .lv-dialog-header {
  padding: 0.75rem 1rem !important;
  border-bottom: 1px solid lightgray !important;
}
.lv-dialog .lv-dialog-content {
  padding: 0 1rem 1rem !important;
}
.app-video {
  height: 55vh;
  object-fit: contain;
  width: auto;
}
.app-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}
.lv-badge__wrap {
  display: inline-block !important;
}
</style>
