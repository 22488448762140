<template>
  <div class="coming-soon-wrapper" :style="backgroundStyle">
    <div class="content-wrapper">
      <h1
        class="coming-soon-title"
        data-aos="fade-down"
        data-aos-duration="1000"
      >
        Robothon 2.0
      </h1>

      <div class="event-date" data-aos="fade-up" data-aos-duration="1000">
        {{
          targetDate.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "long",
            year: "numeric",
          })
        }}
      </div>

      <h2
        class="coming-soon-subtitle"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        Coming Soon
      </h2>
      <div
        class="countdown-wrapper"
        data-aos="zoom-in"
        data-aos-duration="1000"
        data-aos-delay="500"
      >
        <div class="countdown-item">
          <span class="countdown-number">{{ days }}</span>
          <span class="countdown-label">Days</span>
        </div>
        <div class="countdown-item">
          <span class="countdown-number">{{ hours }}</span>
          <span class="countdown-label">Hours</span>
        </div>
        <div class="countdown-item">
          <span class="countdown-number">{{ minutes }}</span>
          <span class="countdown-label">Minutes</span>
        </div>
        <div class="countdown-item">
          <span class="countdown-number">{{ seconds }}</span>
          <span class="countdown-label">Seconds</span>
        </div>
      </div>
      <router-link
        to="/robothon-registration"
        class="button-primary coming-soon-button"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="1000"
      >
        Register Now
      </router-link>
    </div>
  </div>
</template>

<script>
import robothonBg from "@/assets/images/robothon-bg.jpg";

export default {
  name: "ComingSoon",
  data() {
    return {
      targetDate: new Date("2025-04-12"),
      days: "00",
      hours: "00",
      minutes: "00",
      seconds: "00",
      interval: null,
    };
  },
  computed: {
    backgroundStyle() {
      return {
        backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${robothonBg})`,
      };
    },
  },
  mounted() {
    this.startCountdown();
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
  methods: {
    startCountdown() {
      this.interval = setInterval(() => {
        const now = new Date().getTime();
        const distance = this.targetDate.getTime() - now;

        this.days = Math.floor(distance / (1000 * 60 * 60 * 24))
          .toString()
          .padStart(2, "0");
        this.hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        )
          .toString()
          .padStart(2, "0");
        this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
          .toString()
          .padStart(2, "0");
        this.seconds = Math.floor((distance % (1000 * 60)) / 1000)
          .toString()
          .padStart(2, "0");

        if (distance < 0) {
          clearInterval(this.interval);
          this.days = "00";
          this.hours = "00";
          this.minutes = "00";
          this.seconds = "00";
        }
      }, 1000);
    },
  },
};
</script>
