<template>
  <div class="ignite-wrapper">
    <div v-if="dataLoading" class="custom-loader-wrapper">
      <div class="custom-loader"></div>
    </div>
    <!-- v-else-if="pageData" -->
    <template>
      <!-- Hero Section -->
      <div
        class="full-width ignite-banner"
        :style="{
          'background-image':
            'linear-gradient(to top, transparent, rgba(0, 0, 0, 0.5)), url(' +
            require('@/assets/images/visionbg.jpeg') +
            ')',
        }"
      >
        <div class="ignite-banner-content">
          <div class="ignite-title-container">
            <h1 class="ignite-main-title">
              <span class="ignite-letter">I</span>
              <span class="ignite-letter">G</span>
              <span class="ignite-letter">N</span>
              <span class="ignite-letter">I</span>
              <span class="ignite-letter">T</span>
              <span class="ignite-letter">E</span>
            </h1>
            <div class="ignite-subtitle">
              FOSTERING GROWTH, INSPIRING DREAMS
            </div>
          </div>
        </div>
      </div>

      <!-- Main Content Section -->
      <div class="ignite-section ignite-section-light">
        <div class="container">
          <div class="ignite-content-wrapper">
            <div class="ignite-content-block">
              <p class="ignite-highlight-text">
                The IGNITE Programme at The Elixir School is designed to go
                beyond traditional academics offering students with a dynamic
                and immersive learning experience that nurtures creativity,
                leadership and innovation.
              </p>
              <div class="ignite-pillars">
                <span class="ignite-pillar">Imagine</span>
                <span class="ignite-pillar">Grow</span>
                <span class="ignite-pillar">Innovate</span>
                <span class="ignite-pillar">Transform</span>
                <span class="ignite-pillar">Excel</span>
              </div>
              <p class="ignite-text">
                IGNITE is built on the belief that education should inspire
                students to think beyond the ordinary, challenge their limits,
                and develop essential life skills. Through engaging activities,
                hands-on projects and collaborative learning, students cultivate
                a passion for lifelong learning, gaining the confidence and
                resilience needed to succeed in an ever-evolving world.
              </p>
            </div>

            <div class="ignite-content-block">
              <div class="ignite-focus-areas">
                <div class="ignite-focus-title">Our Focus Areas</div>
                <div class="ignite-focus-grid">
                  <div class="ignite-focus-item">
                    <i class="fas fa-microchip"></i>
                    <span>STEM</span>
                  </div>
                  <div class="ignite-focus-item">
                    <i class="fas fa-palette"></i>
                    <span>Arts</span>
                  </div>
                  <div class="ignite-focus-item">
                    <i class="fas fa-lightbulb"></i>
                    <span>Entrepreneurship</span>
                  </div>
                  <div class="ignite-focus-item">
                    <i class="fas fa-hands-helping"></i>
                    <span>Community Service</span>
                  </div>
                </div>
              </div>
              <p class="ignite-text">
                With a focus on STEM, arts, entrepreneurship and community
                service, IGNITE provides students with a platform to explore
                their interests, develop problem-solving abilities and foster a
                growth mindset. Whether they are designing innovative projects,
                expressing themselves through creative arts, launching
                entrepreneurial initiatives or engaging in meaningful community
                work, students are encouraged to transform ideas into action.
                More than just a co-curricular programme, IGNITE is a journey of
                self-discovery, empowerment and excellence, shaping young minds
                to become future leaders and changemakers in society.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Programs Slider Section -->
      <div class="ignite-section">
        <div class="container">
          <div class="ignite-content-wrapper">
            <h2 class="ignite-section-title">Our Clubs</h2>
            <div class="ignite-programs-container">
              <!-- Throwball Titans -->
              <div class="ignite-program-card">
                <div class="ignite-program-icon">
                  <img
                    src="@/assets/ignite/throwball-titans-icon.svg"
                    alt="Throwball Titans"
                  />
                </div>
                <h3 class="ignite-program-title">Throwball Titans</h3>
                <p class="ignite-program-desc">
                  Join a community of throwball enthusiasts and experience the
                  camaraderie of team sports! Learn new skills, improve your
                  game and develop good sportsmanship while having a great time.
                </p>
              </div>

              <!-- Cricket Craze -->
              <div class="ignite-program-card">
                <div class="ignite-program-icon">
                  <img
                    src="@/assets/ignite/cricket-craze-icon.svg"
                    alt="Cricket Craze"
                  />
                </div>
                <h3 class="ignite-program-title">Cricket Craze</h3>
                <p class="ignite-program-desc">
                  Join the cricket action and have a blast on the field! Learn
                  the fundamentals of the game, improve your skills and enjoy
                  the camaraderie of team play in a fun and supportive
                  environment.
                </p>
              </div>

              <!-- The Fun Flip -->
              <div class="ignite-program-card">
                <div class="ignite-program-icon">
                  <img
                    src="@/assets/ignite/fun-flip-icon.svg"
                    alt="The Fun Flip"
                  />
                </div>
                <h3 class="ignite-program-title">The Fun Flip</h3>
                <p class="ignite-program-desc">
                  Challenge yourself and achieve new heights in gymnastics!
                  Learn progressively challenging skills, build strength and
                  flexibility and experience the satisfaction of mastering new
                  moves.
                </p>
              </div>

              <!-- Beat Jam -->
              <div class="ignite-program-card">
                <div class="ignite-program-icon">
                  <img src="@/assets/ignite/beat-jam-icon.svg" alt="Beat Jam" />
                </div>
                <h3 class="ignite-program-title">Beat Jam</h3>
                <p class="ignite-program-desc">
                  Express yourself through the universal language of music!
                  Explore different instruments, create your own beats and
                  discover the joy of making music that moves you.
                </p>
              </div>

              <!-- All About Tennis -->
              <div class="ignite-program-card">
                <div class="ignite-program-icon">
                  <img
                    src="@/assets/ignite/tennis-icon.svg"
                    alt="All About Tennis"
                  />
                </div>
                <h3 class="ignite-program-title">All About Tennis</h3>
                <p class="ignite-program-desc">
                  Master the strategies and techniques of tennis and elevate
                  your game! Learn advanced shots, develop your court awareness
                  and become a more skilled and strategic player.
                </p>
              </div>

              <!-- Futsal Craze -->
              <div class="ignite-program-card">
                <div class="ignite-program-icon">
                  <img
                    src="@/assets/ignite/futsal-craze-icon.svg"
                    alt="Futsal Craze"
                  />
                </div>
                <h3 class="ignite-program-title">Futsal Craze</h3>
                <p class="ignite-program-desc">
                  Master the strategies and techniques of futsal and elevate
                  your game! Learn advanced ball control, improve your passing
                  and shooting accuracy, and become a more skilled and strategic
                  player.
                </p>
              </div>

              <!-- Mathemagician -->
              <div class="ignite-program-card">
                <div class="ignite-program-icon">
                  <img
                    src="@/assets/ignite/mathemagician-icon.svg"
                    alt="Mathemagician"
                  />
                </div>
                <h3 class="ignite-program-title">Mathemagician</h3>
                <p class="ignite-program-desc">
                  Build your confidence and showcase your mental math magic!
                  Engage in exciting math games and puzzles, unlocking your
                  potential and experiencing the satisfaction of intellectual
                  achievement.
                </p>
              </div>

              <!-- Pod Squads -->
              <div class="ignite-program-card">
                <div class="ignite-program-icon">
                  <img
                    src="@/assets/ignite/pod-squads-icon.svg"
                    alt="Pod Squads"
                  />
                </div>
                <h3 class="ignite-program-title">Pod Squads</h3>
                <p class="ignite-program-desc">
                  Develop valuable technical skills in audio recording, editing,
                  and production! Learn the ins and outs of podcast creation,
                  from interviewing techniques to sound design and bring your
                  podcast vision to life.
                </p>
              </div>

              <!-- Public Speaking -->
              <div class="ignite-program-card">
                <div class="ignite-program-icon">
                  <img
                    src="@/assets/ignite/public-speaking-icon.svg"
                    alt="Public Speaking"
                  />
                </div>
                <h3 class="ignite-program-title">Public Speaking</h3>
                <p class="ignite-program-desc">
                  Speak like a star! Learn to shine in debates, MUNs, interviews
                  and presentations. Boost your confidence and amaze everyone
                  with your speaking skills.
                </p>
              </div>

              <!-- Art Factory -->
              <div class="ignite-program-card">
                <div class="ignite-program-icon">
                  <img
                    src="@/assets/ignite/art-factory-icon.svg"
                    alt="Art Factory"
                  />
                </div>
                <h3 class="ignite-program-title">The Art Factory</h3>
                <p class="ignite-program-desc">
                  Let your imagination take flight as you paint, craft and
                  create in our vibrant art club! Discover innovative ways to
                  turn everyday objects into colorful and inspiring artwork.
                </p>
              </div>

              <!-- Movement and Expressions -->
              <div class="ignite-program-card">
                <div class="ignite-program-icon">
                  <img
                    src="@/assets/ignite/movement-expressions-icon.svg"
                    alt="Movement and Expressions"
                  />
                </div>
                <h3 class="ignite-program-title">Movement & Expressions</h3>
                <p class="ignite-program-desc">
                  Express yourself through dynamic dance moves and high-energy
                  Zumba routines! Get fit, have fun and ignite your passion for
                  movement in a vibrant and supportive atmosphere.
                </p>
              </div>

              <!-- The Royal Gambit -->
              <div class="ignite-program-card">
                <div class="ignite-program-icon">
                  <img
                    src="@/assets/ignite/royal-gambit-icon.svg"
                    alt="Royal Gambit"
                  />
                </div>
                <h3 class="ignite-program-title">The Royal Gambit</h3>
                <p class="ignite-program-desc">
                  Dive into the world of numbers and discover the joy of
                  mathematical thinking! Play games, solve puzzles and develop
                  your mental math skills in a lively and engaging way.
                </p>
              </div>

              <!-- Android Game Development -->
              <div class="ignite-program-card">
                <div class="ignite-program-icon">
                  <img
                    src="@/assets/ignite/android-game-dev-icon.svg"
                    alt="Android Game Development"
                  />
                </div>
                <h3 class="ignite-program-title">Android Game Development</h3>
                <p class="ignite-program-desc">
                  Challenge your problem-solving abilities and logical thinking
                  through game development! Learn coding, design and animation
                  to build your own games from the ground up, fostering
                  creativity and critical thinking.
                </p>
              </div>

              <!-- Junior Tech Wizards -->
              <div class="ignite-program-card">
                <div class="ignite-program-icon">
                  <img
                    src="@/assets/ignite/tech-wizard-icon.svg"
                    alt="Tech Wizard"
                  />
                </div>
                <h3 class="ignite-program-title">Junior Tech Wizards</h3>
                <p class="ignite-program-desc">
                  Step into the magical world of technology! Create cool
                  projects, learn fun computer tricks, and explore the wonders
                  of AI-powered apps. Build your tech superpowers and have a
                  blast while learning.
                </p>
              </div>

              <!-- Eco-Warriors -->
              <div class="ignite-program-card">
                <div class="ignite-program-icon">
                  <img
                    src="@/assets/ignite/eco-warrior-icon.svg"
                    alt="Eco Warrior"
                  />
                </div>
                <h3 class="ignite-program-title">Eco-Warriors</h3>
                <p class="ignite-program-desc">
                  Become a superhero for the planet! Join us to save the
                  environment, plant trees, fight pollution and make the Earth a
                  greener, happier place for everyone.
                </p>
              </div>

              <!-- Wildlife Guardians -->
              <div class="ignite-program-card">
                <div class="ignite-program-icon">
                  <img
                    src="@/assets/ignite/wildlife-guardian-icon.svg"
                    alt="Wildlife Guardian"
                  />
                </div>
                <h3 class="ignite-program-title">Wildlife Guardians</h3>
                <p class="ignite-program-desc">
                  Develop a passion for wildlife conservation and become an
                  advocate for animals! Meet and care for the animals in our
                  mini-zoo, learning about their natural habitats and the
                  challenges they face.
                </p>
              </div>

              <!-- Creative CEOs -->
              <div class="ignite-program-card">
                <div class="ignite-program-icon">
                  <img
                    src="@/assets/ignite/creative-ceo-icon.svg"
                    alt="Creative CEO"
                  />
                </div>
                <h3 class="ignite-program-title">Creative CEOs</h3>
                <p class="ignite-program-desc">
                  Dream of running your own business? Learn to create cool
                  ideas, plan budgets and be your own boss. Let's turn your
                  imagination into real-life success!
                </p>
              </div>

              <!-- Skate Squad -->
              <div class="ignite-program-card">
                <div class="ignite-program-icon">
                  <img
                    src="@/assets/ignite/skate-squad-icon.svg"
                    alt="Skate Squad"
                  />
                </div>
                <h3 class="ignite-program-title">Skate Squad</h3>
                <p class="ignite-program-desc">
                  Start with the basics and progress to impressive tricks as you
                  zoom around on skates. Build your confidence with each new
                  skill you learn and have the time of your life exploring the
                  world of skating.
                </p>
              </div>

              <!-- Word Wizards -->
              <div class="ignite-program-card">
                <div class="ignite-program-icon">
                  <img
                    src="@/assets/ignite/word-wizard-icon.svg"
                    alt="Word Wizard"
                  />
                </div>
                <h3 class="ignite-program-title">Word Wizards</h3>
                <p class="ignite-program-desc">
                  Master the art of wordplay and dominate the Scrabble board!
                  Expand your vocabulary, hone your strategic thinking and
                  experience the thrill of competitive Scrabble.
                </p>
              </div>

              <!-- Organic Kitchen Garden -->
              <div class="ignite-program-card">
                <div class="ignite-program-icon">
                  <img
                    src="@/assets/ignite/organic-kitchen-garden-icon.svg"
                    alt="Organic Kitchen Garden"
                  />
                </div>
                <h3 class="ignite-program-title">Organic Kitchen Garden</h3>
                <p class="ignite-program-desc">
                  Get your hands dirty and grow your own veggies! Learn the
                  secrets of gardening, care for plants and create a mini jungle
                  that's all yours.
                </p>
              </div>

              <!-- The Acting Room -->
              <div class="ignite-program-card">
                <div class="ignite-program-icon">
                  <img
                    src="@/assets/ignite/acting-room-icon.svg"
                    alt="The Acting Room"
                  />
                </div>
                <h3 class="ignite-program-title">The Acting Room</h3>
                <p class="ignite-program-desc">
                  Lights, camera, action! Discover the world of theatre and
                  transform into a star of the stage. Learn acting skills,
                  develop your talents and ignite your passion for performance.
                </p>
              </div>

              <!-- Masterchef -->
              <div class="ignite-program-card">
                <div class="ignite-program-icon">
                  <img
                    src="@/assets/ignite/masterchef-icon.svg"
                    alt="Masterchef"
                  />
                </div>
                <h3 class="ignite-program-title">Masterchef</h3>
                <p class="ignite-program-desc">
                  Experience the joy of cooking and create mouthwatering meals!
                  Learn to make delicious dishes, try exciting new recipes and
                  share your culinary creations with others.
                </p>
              </div>

              <!-- No Flame Culinary -->
              <div class="ignite-program-card">
                <div class="ignite-program-icon">
                  <img
                    src="@/assets/ignite/no-flame-culinary-icon.svg"
                    alt="No Flame Culinary"
                  />
                </div>
                <h3 class="ignite-program-title">No Flame Culinary</h3>
                <p class="ignite-program-desc">
                  Explore the world of fresh ingredients and create flavorful
                  no-stove meals! Learn to make delicious salads, sandwiches and
                  drinks that are both healthy and satisfying.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- <div v-else class="custom-loader-wrapper">
      <h1>Page Not Found 🕵🏻‍♀️</h1>
      <p>
        Oops! 😖 The page you're looking for does not exist or have been
        temporarily unavailable.
      </p>
    </div> -->
  </div>
</template>

<script>
import { convertSpecialChars, smallMedia } from "@/utils/utils";
import axios from "axios";
import "@/assets/css/ignite.css";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default {
  name: "Ignite",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      dataLoading: false,
      pageData: null,
      modules: [Navigation, Pagination],
    };
  },
  created() {
    // this.LoadData();
  },
  methods: {
    convertSpecialChars,
    smallMedia,
    LoadData() {
      this.dataLoading = true;
      let config = {
        method: "get",
        url: `${this.$store.state.domain}/ignites?populate=deep`,
        headers: {
          Authorization: this.$store.state.token,
        },
      };
      axios(config)
        .then((res) => {
          if (res.data.data.length > 0) {
            this.pageData = res.data.data[0].attributes;
          } else {
            this.pageData = null;
          }
        })
        .catch((err) => {
          console.log(err);
          this.pageData = null;
        })
        .finally(() => (this.dataLoading = false));
    },
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
    };
  },
};
</script>

<style scoped>
.style-105126 {
  height: 400px;
  background-size: cover;
  background-position: center;
  margin-bottom: 40px;
}

.indi-section {
  padding: 40px 0;
}

.single-section {
  background: #f8f9fa;
}

.photos {
  text-align: center;
  margin-bottom: 30px;
}

.heading-black,
.heading-red {
  font-size: 2.5rem;
  font-weight: bold;
}

.heading-red {
  color: #dc3545;
  margin-left: 10px;
}

.my-content {
  text-align: center;
  font-size: 1.2rem;
  color: #666;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .heading-black,
  .heading-red {
    font-size: 2rem;
  }

  .my-content {
    font-size: 1rem;
    padding: 0 20px;
  }
}

/* Add these styles to ensure Swiper visibility */
.swiper {
  width: 100%;
  height: 100%;
  padding: 20px 0;
}

.swiper-slide {
  height: auto;
  padding: 20px;
}
</style>
